import React, { createContext, useState, useEffect } from "react";

// Tạo context
const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState(true);

  const toggleMode = () => {
    setIsLightMode((prevMode) => !prevMode);
    localStorage.setItem("theme", isLightMode ? "dark" : "light");
  };
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      setIsLightMode(false);
    }
  }, []);
  return (
    <ThemeContext.Provider value={{ isLightMode, toggleMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
