import React from "react";
import { Modal } from "antd";
import jollibee_avatar from "assets/jollibee_avatar.png";

const PopupWarningFirebase = React.forwardRef(({}, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true);
    },
    hide: () => {
      if (open) {
        setOpen(false);
      }
    },
  }));

  const renderContent = () => {
    return (
      <div className="wifi_container">
        <img src={jollibee_avatar} style={{ width: 200, height: 200 }} />
        <h3 className="wifi_title">Firebase token not found</h3>
        {/* <div className="wifi_content">
                        No firebase token
                    </div> */}
        <div
          className="wifi_content"
          style={{ marginBottom: 10, color: "#404040" }}
        >
          {`Currently, we are unable to retrieve the Firebase token (which is used to receive notifications when there is an order), so login is not possible.
                        Please check: your browser's notification settings and your network connection.
                        After that, please close and reopen the browser.`}
        </div>
      </div>
    );
  };

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-container modal-logout"
      title={<></>}
      visible={open}
      footer={null}
      closable={false}
    >
      <div style={{ fontWeight: "bold", fontSize: 25 }}>{renderContent()}</div>
    </Modal>
  );
});

export default PopupWarningFirebase;
