import {
  apiSubmitCancelListItem,
  apiSubmitCookingListItem,
  apiSubmitReadyListItem,
} from "apis/Order";

export const useUpdateStatus = () => {
  const handleSetListItemCooking = async ({ id, items_id, type }) => {
    const res = await apiSubmitCookingListItem({
      id,
      items_id,
      type,
    });
    return res;
  };
  const handleSetListItemCancel = async ({ id, items_id, type }) => {
    const res = await apiSubmitCancelListItem({
      id,
      items_id,
      type,
    });
    return res;
  };
  const handleSubmitReadyItem = async ({ id, items_id, type }) => {
    const res = await apiSubmitReadyListItem({
      id,
      items_id,
      type,
    });
    return res;
  };
  const updateListItemNextStatus = async ({
    cartId,
    listItemId,
    orderType,
    setIsUpdateLoading,
    setReload,
    setIsLoadingApp,
    orderId,
    nextStatus,
  }) => {
    if (nextStatus === "cooking") {
      setIsUpdateLoading(true);
      const status = await handleSetListItemCooking({
        id: cartId,
        items_id: listItemId,
        type: orderType,
      });
      setIsLoadingApp(false);
      if (status) {
        setReload();
      }
      setIsUpdateLoading(false);
      return;
    }
    if (nextStatus === "cancel") {
      setIsUpdateLoading(true);
      const status = await handleSetListItemCancel({
        id: cartId,
        items_id: listItemId,
        type: orderType,
      });
      setIsLoadingApp(false);
      if (status) {
        setReload();
      }
      setIsUpdateLoading(false);
      return;
    }
    if (nextStatus === "ready") {
      setIsUpdateLoading(true);
      const status = await handleSubmitReadyItem({
        id: cartId,
        items_id: listItemId,
        type: orderType,
      });
      setIsLoadingApp(false);
      if (status) {
        setReload();
      }
      setIsUpdateLoading(false);
      return;
    }
  };
  return {
    updateListItemNextStatus,
  };
};
