import _ from "lodash";
import React, { createContext, useEffect } from "react";
import PopupNetwork from "./PopupNetwork";
import "./index.scss";

export const NetworkContext = createContext({});

const NetworkProvider = ({ children }) => {
  const refPopupNetwork = React.useRef();

  useEffect(() => {
    const handleOffline = () => {
      refPopupNetwork.current?.show();
    };

    const handleOnline = () => {
      refPopupNetwork.current?.hide();
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const onClosePopup = () => {
    window.location.reload();
  };

  return (
    <NetworkContext.Provider value={{}}>
      {children}
      <PopupNetwork ref={refPopupNetwork} onClosePopup={onClosePopup} />
    </NetworkContext.Provider>
  );
};

export default NetworkProvider;
