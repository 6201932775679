import { callApiWithCancel } from "apis/apiWithCancel";
import { callApi } from "..";

export const apiGetListOrder = (data, isCancelMode = false) => {
  return callApiWithCancel(
    `query($ProductId: Int, $CategoryId: Int,$date_create_from: String, $date_create_to: String, $type_of_food: [Int]){ 
      kitchenGetAllItems(
        filter: {
          product_id: $ProductId
          category_id: $CategoryId
          date_create_from: $date_create_from
          date_create_to: $date_create_to
          type_of_food: $type_of_food
        }
      ) {
        quote_items {
          id
          cart_id
          cart_name
          table
          table_name
          created_at
          cooking_at
          ready_at
          product_name
          product_id
          status
          note
          quantity
        }
        order_items {
          id
          cart_id
          cart_name
          order_number
          table
          table_name
          created_at
          cooking_at
          ready_at
          product_name
          product_id
          status
          note
          quantity
        }
      }
    }
    `,
    data,
    true,
    isCancelMode
  );
};

export const apiGetListOrderRefund = () =>
  callApi(
    `{
    merchantOrderHistory(flagRefund: 1) {
      orders {
        address
        created_at
        delivery_time
        firstname
        grand_total
        id
        lastname
        order_number
        payment_method
        qty
        restaurant_id
        shipping_method
        flag_refund
        status
        use_plastic
        shipper_info {
          first_name
          last_name
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }`,
    null
  );

export const apiMoveOrderToAnother = (data) =>
  callApi(
    `mutation ($orderId:Int!, $restaurantId:Int!, $reason:String!) {
    moveOrder(orderId:$orderId, restaurantId:$restaurantId, reason:$reason) {
      result
    }
  }`,
    data
  );

export const apiCookingItem = (data) =>
  callApi(
    `mutation($id: Int! , $type: ItemTypeEnum!){ kitchenSetItemCooking(item_id: $id, item_type: $type) }`,
    data
  );

export const apiSubmitCookingOrder = (data) =>
  callApi(
    `mutation($id: Int!, $type: ItemTypeEnum!){kitchenSetItemReady(item_id: $id, item_type: $type)}`,
    data
  );
export const apiSubmitCookingListItem = ({ id, items_id, type }) =>
  callApi(
    `mutation( $id: Int! , $items_id: [Int]! , $type: ItemTypeEnum!)
    {
        kitchenSetAllItemCooking(entity_id: $id, items_id: $items_id, entity_type: $type) 
    }`,
    {
      id,
      items_id,
      type,
    }
  );
export const apiSubmitCancelListItem = ({ id, items_id, type }) =>
  callApi(
    `mutation( $id: Int! , $items_id: [Int]! , $type: ItemTypeEnum!)
      {
          kitchenSetAllItemCancel(entity_id: $id, items_id: $items_id, entity_type: $type) 
      }`,
    {
      id,
      items_id,
      type,
    }
  );
export const apiSubmitCompleteOrder = (data) =>
  callApi(
    `mutation($id: Int!, $type: ItemTypeEnum!){waiterSetItemDone(item_id: $id, item_type: $type)}`,
    data
  );
export const apiSubmitReadyListItem = ({ id, items_id, type }) =>
  callApi(
    `mutation( $id: Int! , $items_id: [Int]! , $type: ItemTypeEnum!)
      {
          kitchenSetAllItemReady(entity_id: $id, items_id: $items_id, entity_type: $type) 
      }`,
    {
      id,
      items_id,
      type,
    }
  );
export const apiCancelOrder = (data) =>
  callApi(
    `mutation($orderId: Int!){
    cancelOrder(id:$orderId){
      result
    }
  }`,
    data
  );

export const apiGetAllStoreInTheArea = () =>
  callApi(
    `{
      getStoreJsonData {
        url
        version
      }
    }`,
    null
  );

export const apiAcceptShipping = (data) =>
  callApi(
    `mutation (
    $id: Int!
    $type: Int!
  ){
    merchantAcceptShipping (
      id:  $id
      type: $type
    ) {
      result
    }
  }`,
    data
  );

export const apiMerchantReadyToShip = (data) =>
  callApi(
    `mutation($id: Int!){
      readyToShipOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiGetOrderStatusHistory = (data) =>
  callApi(
    `query($orderId: Int!){
    getOrderStatusHistory(
        orderId: $orderId
    ) {
        list {
            created_at
            status
            comment
        }
    }
}`,
    data
  );

export const apiGetDetailOrder = (data) =>
  callApi(
    `query($id: Int) {
      orderDetail(id:$id) {
        id
        order_number
        created_at
        updated_at
        grand_total
        status
        flag_refund
        payment_method
        payment_method_code
        firstname
        lastname
        phone
        address
        customer_comment
        items {
          name
          qty
          price
          start
          done
          options {
              name
              qty
              price
              start
              done
          }
        }
        discount {
            amount {
                currency
                value
            }
            label
        }
        shipping_method
        use_plastic
        note
        customer_phone
        assign_from
        assign_reason
        shipping_amount
      }
  }`,
    data
  );
export const apiBomOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      bomOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiCompleteOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      completeOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiShipping = (data) =>
  callApi(
    `mutation($id:Int!) {
      shippingOrder(id:$id){
        result
      }
    }`,
    data
  );

export const apiApproveRefund = (data) =>
  callApi(
    `mutation($id:Int!) {
      approveRefund(orderId:$id){
        result
      }
    }`,
    data
  );

export const apiGetOrderHistory = (data) =>
  callApi(
    `query($currentPage: Int, $pageSize: Int, $increment_id: String) {
      merchantOrderHistory(currentPage: $currentPage, pageSize: $pageSize, filter: { increment_id: $increment_id}) {
          orders {
              id
              order_number
              created_at
              updated_at
              grand_total
              status
              flag_refund
              payment_method
              payment_method_code
              firstname
              lastname
              phone
              address
              customer_comment
              discount {
                  amount {
                      currency
                      value
                  }
                  label
              }
              items {
                  name
                  qty
                  price
                  options {
                      name
                      qty
                      price
                  }
              }
              shipping_method
              use_plastic
              note
              customer_phone
              assign_from
              assign_reason
              shipping_amount
          }
          page_info {
              page_size
              current_page
              total_pages
          }
      }
  }`,
    data
  );

export const apiGetReasonCanel = () =>
  callApi(
    `query {
      storeConfig {
          reasons_cancel
      }
    }`
  );

export const apiGetTurnOffAppSound = () =>
  callApi(
    `query{
      storeConfig {
          turn_off_app_sound
      }
    }`
  );

export const apiGetTimeReloadOrder = () =>
  callApi(
    `query{
      storeConfig {
        time_reload_order
      }
    }`
  );

export const apiNotRefund = (data) =>
  callApi(
    ` mutation ($id: Int!, $shipperId: Int!) {
      noRefund(orderId: $id, shipperId: $shipperId) {
        result
      }
    }`,
    data
  );

export const apiPushSocketIdToServer = (data) =>
  callApi(
    `   mutation ($socketId: String!) {
      receiveSocketId(socketId: $socketId) {
        result
      }
    }`,
    data
  );
export const apiKitchenCancelItem = (data) =>
  callApi(
    `mutation($id: Int! , $type: ItemTypeEnum!){ kitchenCancelItem(item_id: $id, item_type: $type) }`,
    data
  );
