import React from "react";

export default function ButtonSelectMode({
  onClick = () => {},
  isSelect = false,
  title = "Theo đơn",
}) {
  return (
    <div
      style={{
        cursor: "pointer",
        background: isSelect ? "#FF9D00" : "#FFF5E5",
        height: 40,
        paddingInline: 24,
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: isSelect ? "black" : "#F8CC3E",
        fontSize: 17,
        fontWeight: isSelect ? "bold" : "normal",
      }}
      onClick={onClick}
    >
      {title}
    </div>
  );
}
