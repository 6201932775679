import { callApi } from "..";

export const apiLogin = (data) =>
  callApi(
    `mutation ($username: String!,$password: String!) {
      generateMerchantToken(username: $username,password: $password) {
      token
      account_type
    }
  }`,
    data,
    false
  );

export const apiLogout = (data) =>
  callApi(
    `mutation ($fcmToken: String!) {
      revokeStaffToken(fcmToken: $fcmToken) {
          result
        }
      }`,
    data
  );
