const REACT_APP_URL_IP = process.env.REACT_APP_URL_IP;

module.exports.URL_LOGIN_WITH_PASS = `${REACT_APP_URL_IP}/auth/login`;
module.exports.URL_SIGNUP = `${REACT_APP_URL_IP}/auth/register`;
module.exports.URL_GET_USER_INFO = `${REACT_APP_URL_IP}/user/profile`;
module.exports.URL_PROJECT = `${REACT_APP_URL_IP}/project`;
module.exports.URL_UPLOAD = `${REACT_APP_URL_IP}/upload/img`;
module.exports.URL_VERIFY_EMAIL = `${REACT_APP_URL_IP}/auth/verifyEmail`;
module.exports.URL_FORGOT_PASS = `${REACT_APP_URL_IP}/auth/forgot_password`;
module.exports.URL_RESET_PASS = `${REACT_APP_URL_IP}/auth/change_password`;
module.exports.URL_TIMEZONE = `${REACT_APP_URL_IP}/timezone`;
module.exports.URL_VERIFY_RESET_MAIL = `${REACT_APP_URL_IP}/auth/checkValidResetPassword`;
